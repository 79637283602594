import React from 'react';

const ToggleSectionOfCaptureUrls = ({ title, toggleState, onToggle, onHelpClick, noteMultipleCapture = '' }) => (
  <div className="d-flex show-toggle-multiple">
    <div
      className="font-weight-bold cursor-pointer fs-title-popup-url text-left text-black text-underline fs-17"
      onClick={onToggle}
    >
      <i
        className={`pr-2 text-muted fa fa-chevron-${
          toggleState ? "up" : "down"
        }`}
      ></i>
      {title}
    </div>
    <i
      className="fas fa-question-circle ml-1 scale-1_3 cursor-pointer text-black"
      onClick={onHelpClick}
    ></i>
    <span class="note_multiple_capture">{noteMultipleCapture}</span>
  </div>
);

export default ToggleSectionOfCaptureUrls;
